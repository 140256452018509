import logo from './logo_white.svg';
import shopify from './shopify.webp';
import marketing from './marketing.webp';
import './App.css';
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    script.onload = () => {
      if (window.Tally) {
        window.Tally.loadEmbeds();
      } else {
        document.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((e) => {
          e.src = e.dataset.tallySrc;
        });
      }
    };
    script.onerror = script.onload;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="App">
      <div className="App-hero">
        <header className="App-header">
          <div className="App-container">
            <img src={logo}className="App-logo" alt="logo" />
            <nav className="App-nav">
              <a href="#shopify">Shopify</a>
              <a href="#marketing"> Marketing</a>
              <a href="#ai">AI</a>
              <a href="#contact">Contact</a>
            </nav>
          </div>
        </header>
        <p className="hero-text">
          A premier e-commerce agency for Shopify Development, Marketing and AI business solutions.
        </p>
      </div>
      <div className="who-we-are">
        <h1>Illuminate your brand</h1>
        <p>
        Neon Nimbus is your beacon in the e-commerce storm, guiding you toward Shopify success. With over 30 collective years in the game, we’ve mastered both development and marketing, crafting custom solutions that sync with your brand’s goals. Whether it’s building slick themes, integrating powerful apps, or supercharging your digital presence, we deliver nothing but excellence, every single time.
        </p>

        <p>We’re all about innovation, reliability, and a deep understanding of the e-commerce battlefield. Our goal? Not just to meet your expectations, but to blow them out of the water—keeping you ahead of the curve and your competition.</p>

        <p>Neon Nimbus is here to empower your business, becoming the partner you rely on for long-term growth. Let's illuminate your brand and secure your spot at the top of the Ecommerce world.
        </p>
      </div>
      <div id="shopify" className="shopify">
        <h1>Our Shopify Services</h1>
        <p>Neon Nimbus is your one-stop shop for all things Shopify. With 15+ years of expertise, we enhance your store and brand to dominate the e-commerce landscape with development and management.</p>
        <div className="shopify-container">
          <div className="shopify-image">
            <img src={shopify} alt='Shopify Development & Management'/>
          </div>
          <div className="shopify-text">
            <ul>
              <li>
                <strong>Consulting </strong> 
                <span>Navigate e-commerce challenges like a pro.</span>
              </li>
              <li>
                <strong>Performance </strong>
                <span>Speed and efficiency to elevate user experience.</span>
              </li>
              <li>
                <strong>Custom Themes </strong>
                <span>Unique Shopify themes that reflect your brand’s vibe.</span>
              </li>
              <li>
                <strong>App Integration</strong>
                <span>Plug in third-party apps without a hitch.</span>
              </li>
              <li>
                <strong>Custom Apps</strong>
                <span>Tailored app solutions for your specific needs.</span>
              </li>
              <li>
                <strong>Data Continuity</strong>
                <span>Keep your data safe and sound</span>
              </li>
              <li>
                <strong>Migration</strong>
                <span>Smooth transitions to Shopify</span>
              </li>
              <li>
                <strong>Headless Commerce</strong>
                <span>Unlock next-level flexibility with advanced solutions.</span>
              </li>
              <li>
                <strong>Store Management</strong>
                <span>Keep your store running like a well-oiled machine.</span>
              </li>
              <li>
                <strong>SEO Optimization</strong>
                <span>Get noticed on Google and beyond.</span>
              </li>
            </ul>
          </div>
        </div>        
      </div>
      <div id="marketing" className="marketing">
        <h1>Our Marketing Services</h1>
        <p>Elevate your brand with Neon Nimbus, your ultimate partner in digital marketing. With over 15 years of experience, we craft compelling strategies that amplify your online presence, drive engagement, and ensure your business stands out in the digital landscape. Let us transform your brand into a market leader.</p>
        <div className="marketing-container">
          <div className="marketing-image">
            <img src={marketing} alt='Ecommerce Marketing'/>
          </div>
          <div className="marketing-text">
            <ul>
              <li>
                <strong>SEO Mastery</strong> 
                <span>Drive traffic with optimized content and strategy.</span>
              </li>
              <li>
                <strong>Branding</strong> <span>Craft a brand identity that sticks.</span>
              </li>
              <li>
                <strong>Content Strategy</strong> 
                <span>Engage and convert with killer content.</span>
              </li>
              <li>
                <strong>Advertising</strong> 
                <span>Get your message out there, loud and clear.</span>
              </li>
              <li>
                <strong>Digital Marketing</strong> 
                <span>From PPC to social media, we’ve got you covered.</span>
              </li>
              <li>
                <strong>Email Marketing</strong> 
                <span>Nurture leads and spike sales with targeted campaigns.</span>
              </li>
              <li>
                <strong>Social Media</strong> 
                <span>Build a buzz and keep your audience hooked.</span>
              </li>
              <li>
                <strong>Consulting</strong> 
                <span>Turbocharge your marketing with expert advice.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="ai" className="ai">
        <h1>AI & Chatbots</h1>
        <p>Elevate your customer interactions with Neon Nimbus, your trusted partner in AI and chatbot integration. With advanced technology, we craft intelligent solutions that enhance user experiences, streamline workflows, and meet your business needs. From integrating chatbots into your website to automating customer engagement, we help your brand stay ahead in the digital era. Let us transform your customer service with smart, seamless automation.</p>
        <div className="ai-container">
          <div className="ai-text">
            <ul>
              <li>
                <strong>Chatbot Development for Websites</strong> 
                <span>Create custom chatbots for websites that handle customer queries, offer support, and guide users 24/7.</span>
              </li>
              <li>
                <strong>Lead Generation Bots</strong> 
                <span>Automate lead capture with chatbots that interact with potential customers, gather contact details, and qualify leads.</span>
              </li>
              <li>
                <strong>E-commerce Chatbots</strong> 
                <span>Build chatbots to assist customers with product recommendations, purchases, and transactions in online stores.</span>
              </li>
              <li>
                <strong>Customer Support AI</strong> 
                <span>Implement AI-driven bots that manage FAQs, troubleshoot issues, and handle customer complaints efficiently.</span>
              </li>
              <li>
                <strong>AI-Powered Virtual Assistants</strong> 
                <span>Develop virtual assistants to help businesses automate scheduling, task management, and personalized customer service.</span>
              </li>
              <li>
                <strong>AI Content Generation and Personalization</strong> 
                <span>Use AI tools to create personalized content for websites, emails, and marketing, improving engagement.</span>
              </li>
              <li>
                <strong>AI Analytics and Insights</strong> 
                <span>Monitor customer feedback and social media interactions to gauge sentiment and respond effectively.</span>
              </li>
              <li>
                <strong>AI-Powered Workflow Automation</strong> 
                <span>Automate repetitive business tasks like order processing, email follow-ups, and administrative duties with AI.</span>
              </li>
              <li>
                <strong>Social Media Chatbots</strong> 
                <span>Build bots that engage with customers on social platforms like Facebook, Instagram, and WhatsApp.</span>
              </li>
              <li>
                <strong>AI for Sales Automation</strong> 
                <span>Implement AI tools to automate sales processes, manage CRM entries, and follow up with leads efficiently.</span>
              </li>
              <li>
                <strong>Chatbot Training and Optimization</strong> 
                <span>Provide services to optimize and retrain existing chatbots, improving their accuracy and responsiveness over time.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id='contact' className="contact">
        <div className='contact-container'>
          <h1>Contact Us</h1>
          <a href="mailto:hello@neonnimbus.agency">hello@neonnimbus.agency</a>
          <iframe data-tally-src="https://tally.so/embed/mRYdY9?hideTitle=1&transparentBackground=1&dynamicHeight=1" loading="lazy" width="100%" height="1" frameborder="0" marginheight="0" marginwidth="0" title="Contact Us"></iframe>
        </div>         
      </div>
      <footer>
        <p>© 2024 Creative Commerce LLC</p>
      </footer>
    </div>
  );
}

export default App;
